import React, { FC, forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeColorsKeys } from '../../styles/tokens/colors';
import { useBackgroundColor } from '../../hooks/useBackgroundColor';
import { ThemeSpaceKeys } from '../../styles/tokens/space';
import tokens from './tokens';

// JSX

// Interface

export interface SurfaceProps {
  children?: React.ReactNode | undefined;
  backgroundColor?: ThemeColorsKeys;
  /**
   * Remove internal padding
   */
  collapse?: boolean;
  /**
   * Remove internal verticle padding
   */
  collapseVertical?: boolean;
  /**
   * overide default padding property
   */
  padding?: ThemeSpaceKeys | [ThemeSpaceKeys, ThemeSpaceKeys];
  /**
   * overide the max-width to 496px
   */
  narrow?: boolean;
  /**
   * Add negative margin on mobile so it spans full width of page
   */
  mobileFullWidth?: boolean;
  /**
   * Add border
   */
  hasBorder?: boolean;
}

// Styles

const StyledSurface = styled.div<SurfaceProps>`
  ${({
    theme: { space, borderRadii, maxMedia, colors },
    backgroundColor,
    collapse,
    collapseVertical,
    padding,
    narrow,
    mobileFullWidth,
    hasBorder,
  }) => css`
    border-radius: ${borderRadii[tokens.borderRadius]};
    ${!collapse &&
    css`
      ${Array.isArray(padding)
        ? `padding: ${space[padding[0]]} ${space[padding[1]]};`
        : `padding: ${
            padding
              ? space[padding]
              : `${space.fluidMedium} ${space.fluidLarge}`
          };`}
    `}

    ${collapseVertical &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}

    // Background and color
    ${backgroundColor && useBackgroundColor(backgroundColor)};

    ${hasBorder &&
    css`
      border: 1px solid ${colors[tokens.borderColor]};
    `}

    // Max width
    max-width: ${narrow ? '496px' : 'none'};

    // Full width on mobile

    ${mobileFullWidth &&
    css`
      @media (max-width: ${maxMedia.medium}) {
        margin-left: -${space.medium};
        margin-right: -${space.medium};
        border-radius: 0;
      }
    `}
  `};
`;

export const Surface = forwardRef<HTMLDivElement, SurfaceProps>(
  (
    {
      children,
      backgroundColor,
      collapse,
      collapseVertical,
      padding,
      narrow,
      mobileFullWidth,
      ...rest
    },
    ref
  ) => (
    <StyledSurface
      ref={ref}
      narrow={narrow}
      backgroundColor={backgroundColor}
      collapse={collapse}
      collapseVertical={collapseVertical}
      className={`surface--${backgroundColor}`}
      padding={padding}
      mobileFullWidth={mobileFullWidth}
      {...rest}
    >
      {children}
    </StyledSurface>
  )
);

Surface.displayName = 'Surface';

export default Surface;
